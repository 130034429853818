import './bootstrap';
import '../css/app.css';

import Alpine from 'alpinejs';
import * as Sentry from "@sentry/browser";

// window.Alpine = Alpine;

Sentry.init({
    dsn: "https://fb7ea63529b146d887b2b018aa86e0cc@o79684.ingest.sentry.io/6571433",
    environment: import.meta.env.PROD ? "Production" : "Development",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: import.meta.env.PROD ? 0.05 : 0,
});

Alpine.start();
